import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { fetchData } from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";

const FeatureGamificationRulesCreate = () => {
  const [breadcrumbItems] = useState([]);

  let { id } = useParams();
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(id ? true : false);

  const [formData, setFormData] = useState({
    days: "",
    sequence: "",
    is_deleted: 0,
    is_active: 1,
  });

  useEffect(() => {
    getData(id);
  }, []);

  const getData = async (id) => {
    try {
      let payload = {
        modelName: "remove_download_days_masters",
      };
      if (id) {
        payload.whereCondition = { id: id };
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.data && responseData.data.length > 0) {
        if (id) {
          setFormData(responseData.data[0]);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.days) {
      return toast.warning("All fields are required");
    }

    if (formData.days) {
      formData.days = formData.days + ' Days'
    }
    
    try {
      setLoadingData(true);
      var payload = {
        modelName: "remove_download_days_masters",
        inputData: formData,
      };
      if (id) {
        payload.id = id;
      }
      const responseData = await fetchData("createAndUpdateMaster", payload);
      if (responseData.code == 1) {
        toast.success("Offline Download Storage saved successfully!");
        navigate("/offline-download-storage-period-list");
      } else {
        toast.error("Error while saving Offline Download Storage!");
        navigate("/offline-download-storage-period-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE OFFLINE DOWNLOAD STORAGE PERIOD"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      onSubmit={handleSubmit}
                      className="needs-validation">
                      <>
                        {" "}
                        <Row>
                          <Col md="6">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="validationCustom01">
                                Days
                                <Asterisk />
                              </Label>
                              <div style={{ position: "relative" }}>
                                <input
                                  name="days"
                                  type="number"
                                  errorMessage="Enter Days"
                                  className="form-control"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  id="validationCustom01"
                                  onChange={handleTextChange}
                                  value={formData.days}
                                  style={{ paddingRight: "40px" }}
                                  min="1"
                                />
                                <span
                                  style={{
                                    position: "absolute",
                                    right: "9px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    pointerEvents: "none",
                                    color: "#6c757d",
                                  }}>
                                  Days
                                </span>
                              </div>
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Sequence
                                <Asterisk />
                              </Label>
                              <AvField
                                name="sequence"
                                placeholder="Sequence"
                                type="number"
                                errorMessage="Enter sequence"
                                className="form-control"
                                min="1"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={handleTextChange}
                                value={formData.sequence}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="invalidCheck"
                                name="is_active"
                                checked={formData.is_active}
                                onChange={handleTextChange}
                                value={formData.is_active}
                                required=""
                              />
                              <label
                                className="form-check-label"
                                htmlFor="invalidCheck">
                                Is Active
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className=" waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className=" waves-effect waves-light me-1"
                          onClick={() =>
                            navigate("/offline-download-storage-period-list")
                          }>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FeatureGamificationRulesCreate;
