import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import {
  downloadBlobData,
  dropdownData,
  fetchData,
  image_url,
} from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage, LoaderDownloadMedia } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";
import downloadIcon from "../../../assets/images/download.png";
import removeIcon from "../../../assets/images/remove.png";

const optionEnvironment = [
  {
    options: [
      { label: "development", value: 1 },
      { label: "staging", value: 2 },
      { label: "production", value: 3 },
    ],
  },
];

const MobileAppSettingsCreate = (props) => {
  const regexImageUrl =
    "^(https?:\\/\\/(www\\.)?|www\\.)[^\\s/$.?#].[^\\s]*\\.(jpg|jpeg|png|gif|bmp|svg|webp)(\\/[^\\s]*)?$";
  const regexDdownloadUrl =
    "^(https?://(www.)?|www.)[^s/$.?#].[^s]*.(zip|exe|dmg|tar|gz|rar|7z|pdf|doc|docx|xls|xlsx|ppt|pptx)(/[^s]*)?$";
  const regexWebsiteUrl =
    "^(https?://(www.)?|www.)[^s/$.?#].[^s]*.(com|net|org|gov|edu|io|in|info|biz|co|us)(/[^s]*)?$";
  const imageErrorMessage =
    "Enter a valid URL start with 'http://' or 'www.' and end with a valid image domain (jpg, png, gif, bmp, svg, webp).";
  const downloadErrorMessage =
    "Enter a valid URL start with 'http://' or 'www.' and end with a valid download domain (zip, exe, pdf, doc, ppt).";
  const websiteErrorMessage =
    "Enter a valid URL star with 'http://' or 'www.' and end with a valid website domain (com, net, org, gov, edu, io, us).";

  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  const navigate = useNavigate();
  let { id } = useParams();
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantlist, setTenantlist] = useState([]);
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);
  const [tenantError, setTenantError] = useState(false);
  const [generalIsVisible, setgeneralIsVisible] = useState(true);
  const [IOSsetingIsVisible, setIOSsetingIsVisible] = useState(false);
  const [androidIsVisible, setAndroidIsVisible] = useState(false);
  const [IBOIsVisible, setIBOIsVisible] = useState(false);
  const [guestIsVisible, setGuestsVisible] = useState(false);
  const [loadingData, setLoadingData] = useState(id ? true : false);

  const [
    isMediaRemoveAndDownloadIosScreen,
    setIsMediaRemoveAndDownloadIosScreen,
  ] = useState(false);
  const [isMediaRemoveAndDownloadIosLogo, setIsMediaRemoveAndDownloadIosLogo] =
    useState(false);
  const [
    isMediaRemoveAndDownloadIosGuestScreen,
    setIsMediaRemoveAndDownloadIosGuestScreen,
  ] = useState(false);
  const [
    isMediaRemoveAndDownloadIosGuestLogo,
    setIsMediaRemoveAndDownloadIosGuestLogo,
  ] = useState(false);
  const [
    isMediaRemoveAndDownloadAndroidScreen,
    setIsMediaRemoveAndDownloadAndroidScreen,
  ] = useState(false);
  const [
    isMediaRemoveAndDownloadAndroidLogo,
    setIsMediaRemoveAndDownloadAndroidLogo,
  ] = useState(false);
  const [
    isMediaRemoveAndDownloadAndroidGuestScreen,
    setIsMediaRemoveAndDownloadAndroidGuestScreen,
  ] = useState(false);
  const [
    isMediaRemoveAndDownloadAndroidGuestLogo,
    setIsMediaRemoveAndDownloadAndroidGuestLogo,
  ] = useState(false);

  const [iosWelcomeScreenLoadingData, setIosWelcomeScreenLoadingData] =
    useState(false);
  const [iosWelcomeLogoLoadingData, setIosWelcomeLogoLoadingData] =
    useState(false);
  const [iosGuestWelcomeLoadingData, setIosGuestWelcomeLoadingData] =
    useState(false);
  const [iosGuestWelcomeLogoLoadingData, setIosGuestWelcomeLogoLoadingData] =
    useState(false);
  const [androidWelcomeScreenLoadingData, setAndroidsWelcomeScreenLoadingData] =
    useState(false);
  const [androidWelcomeLogoLoadingData, setAndroidsWelcomeLogoLoadingData] =
    useState(false);
  const [
    androidGuestWelcomeScreenLoadingData,
    setAndroidGuestWelcomeScreenLoadingData,
  ] = useState(false);
  const [
    androidGuestWelcomeLogoLoadingData,
    setAndroidGuestWelcomeLogoLoadingData,
  ] = useState(false);

  const [formData, setFormData] = useState({
    inputData: {
      tenant_id: "",
      app_name: "",
      social_media_login: 0,
      set_default_tab: 0,
      guest_signup: 0,
      onboarding_user_default_friends: ["user123", "user456", "user789"],
      default_profile_image_enable: Number(0),
      default_profile_image: "",
      powered_by_logo_url: "",
      loading_screen_background_color: "",
      welcome_logo_url: "",
      welcome_background_url: "",
      login_logo_url: "",
      login_background_image_url: "",
      login_button_color: "",
      terms_of_use: "",
      tou_link_url: "https://example.com/terms",
      environment_type: 1,
      ios_app_open_url: "",
      ios_app_download_url: "",
      google_play_download_url: "",
      android_app_id: "",
      ios_app_id: "",

      landing_screen: [
        {
          type: 1,
          landing_page_image: "",
          landing_page_title: "",
          landing_page_sub_title: "",
          sequence: "",
          landing_page_text: "",
          landing_page_backgroup_color: "",
        },
      ],

      landing_screen_ios: [
        {
          type: 1,
          landing_page_image: "",
          landing_page_title: "",
          landing_page_sub_title: "",
          sequence: "",
          landing_page_text: "",
          landing_page_backgroup_color: "",
        },
      ],

      guest_landing_screen: [
        {
          type: 1,
          landing_page_image: "",
          landing_page_title: "",
          landing_page_sub_title: "",
          sequence: "",
          landing_page_text: "",
          landing_page_backgroup_color: "",
        },
      ],

      guest_landing_screen_ios: [
        {
          type: 1,
          landing_page_image: "",
          landing_page_title: "",
          landing_page_sub_title: "",
          sequence: "",
          landing_page_text: "",
          landing_page_backgroup_color: "",
        },
      ],

      welcome_screen: {
        welcome_title: "",
        welcome_screen: "",
        welcome_logo: "",
        welcome_background_color: "",
      },

      welcome_screen_ios: {
        welcome_title: "",
        welcome_screen: "",
        welcome_logo: "",
        welcome_background_color: "",
      },

      guest_welcome_screen: {
        guest_welcome_title: "",
        guest_welcome_screen: "",
        guest_welcome_logo: "",
        guest_welcome_background_color: "",
      },

      guest_welcome_screen_ios: {
        guest_welcome_title: "",
        guest_welcome_screen: "",
        guest_welcome_logo: "",
        guest_welcome_background_color: "",
      },

      is_active: 1,
    },

    uploadImage: [
      {
        field_name: "default_profile_image",
        folderPath: "",
        imagePath: "",
      },
      {
        field_name: "landing_screen[0].landing_page_image",
        folderPath: "",
        imagePath: "",
      },
      {
        field_name: "landing_screen_ios[0].landing_page_image",
        folderPath: "",
        imagePath: "",
      },
      {
        field_name: "guest_landing_screen[0].landing_page_image",
        folderPath: "",
        imagePath: "",
      },
      {
        field_name: "guest_landing_screen_ios[0].landing_page_image",
        folderPath: "",
        imagePath: "",
      },
      {
        field_name: "welcome_screen.welcome_screen",
        folderPath: "",
        imagePath: "",
      },
      {
        field_name: "welcome_screen.welcome_logo",
        folderPath: "",
        imagePath: "",
      },
      {
        field_name: "welcome_screen_ios.welcome_screen",
        folderPath: "",
        imagePath: "",
      },
      {
        field_name: "welcome_screen_ios.welcome_logo",
        folderPath: "",
        imagePath: "",
      },

      {
        field_name: "guest_welcome_screen.guest_welcome_screen",
        folderPath: "",
        imagePath: "",
      },
      {
        field_name: "guest_welcome_screen.guest_welcome_logo",
        folderPath: "",
        imagePath: "",
      },
      {
        field_name: "guest_welcome_screen_ios.guest_welcome_screen",
        folderPath: "",
        imagePath: "",
      },
      {
        field_name: "guest_welcome_screen_ios.guest_welcome_logo",
        folderPath: "",
        imagePath: "",
      },
    ],
  });

  const [landingIOSPageIBOImages, setLandingIOSPageIBOImages] = useState([]);
  const [landingIOSPageGuestImages, setLandingIOSPageGuestImages] = useState(
    []
  );

  const [landingAndroidPageIBOImages, setLandingAndroidPageIBOImages] =
    useState([]);
  const [landingAndroidPageGuestImages, setLandingAndroidPageGuestImages] =
    useState([]);

  const [welcomeScreenAndroidImg, setWelcomeScreenAndroidImg] = useState(null);
  const [welcomeLogoAndroidImg, setWelcomeLogoAndroidImg] = useState(null);

  const [welcomeScreenIOSImg, setWelcomeScreenIOSImg] = useState(null);
  const [welcomeLogoIOSLImg, setWelcomeLogoIOSLImg] = useState(null);

  const [guestWelcomeScreenIOSImg, setGuestWelcomeScreenIOSImg] =
    useState(null);
  const [guestWelcomeLogoIOSImg, setGuestWelcomeLogoIOSImg] = useState(null);

  const [guestWelcomeScreenAndroidImg, setGuestWelcomeScreenAndroidImg] =
    useState(null);
  const [guestWelcomeLogoAndroidImg, setGuestWelcomeLogoAndroidImg] =
    useState(null);

  const fileInputRefs = useRef([]);
  const fileInputGuestRefs = useRef([]);

  const [fetchedData, setFetchedData] = useState(null);

  useEffect(() => {
    getData(id);
  }, []);

  useEffect(() => {
    if (fetchedData) {
      let data = fetchedData;
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          ...data,
        },
      }));

      if (data.welcome_screen.welcome_screen) {
        const newLandingPageImages = data.welcome_screen.welcome_screen;
        setWelcomeScreenAndroidImg(image_url + newLandingPageImages);
      }

      if (data.welcome_screen.welcome_logo) {
        const newLandingPageImages = data.welcome_screen.welcome_logo;
        setWelcomeLogoAndroidImg(image_url + newLandingPageImages);
      }

      if (data.welcome_screen_ios.welcome_screen) {
        const newLandingPageImages = data.welcome_screen_ios.welcome_screen;
        setWelcomeScreenIOSImg(image_url + newLandingPageImages);
      }

      if (data.welcome_screen_ios.welcome_logo) {
        const newLandingPageImages = data.welcome_screen_ios.welcome_logo;
        setWelcomeLogoIOSLImg(image_url + newLandingPageImages);
      }

      if (data.guest_welcome_screen.guest_welcome_screen) {
        const newLandingPageImages =
          data.guest_welcome_screen.guest_welcome_screen;
        setGuestWelcomeScreenAndroidImg(image_url + newLandingPageImages);
      }

      if (data.guest_welcome_screen.guest_welcome_logo) {
        const newLandingPageImages =
          data.guest_welcome_screen.guest_welcome_logo;
        setGuestWelcomeLogoAndroidImg(image_url + newLandingPageImages);
      }

      if (data.guest_welcome_screen_ios.guest_welcome_screen) {
        const newLandingPageImages =
          data.guest_welcome_screen_ios.guest_welcome_screen;
        setGuestWelcomeScreenIOSImg(image_url + newLandingPageImages);
      }

      if (data.guest_welcome_screen_ios.guest_welcome_logo) {
        const newLandingPageImages =
          data.guest_welcome_screen_ios.guest_welcome_logo;
        setGuestWelcomeLogoIOSImg(image_url + newLandingPageImages);
      }

      if (data.landing_screen_ios !== null) {
        const newLandingPageImages = data.landing_screen_ios.map(
          (screen) => image_url + screen.landing_page_image
        );
        setLandingIOSPageIBOImages(newLandingPageImages);
      }

      if (data.landing_screen !== null) {
        const newLandingPageImages = data.landing_screen.map(
          (screen) => image_url + screen.landing_page_image
        );
        setLandingAndroidPageIBOImages(newLandingPageImages);
      }

      if (data.guest_landing_screen_ios !== null) {
        const newLandingPageImages = data.guest_landing_screen_ios.map(
          (screen) => image_url + screen.landing_page_image
        );
        setLandingIOSPageGuestImages(newLandingPageImages);
      }

      if (data.guest_landing_screen !== null) {
        const newLandingPageImages = data.guest_landing_screen.map(
          (screen) => image_url + screen.landing_page_image
        );
        setLandingAndroidPageGuestImages(newLandingPageImages);
      }
    }
  }, [fetchedData]);

  const getData = async (id) => {
    try {
      getTenantDropdownData();

      let payload = {
        modelName: "application_settings",
      };
      if (id) {
        payload.whereCondition = { id: id };
      }
      const responseData = await fetchData("getMasterList", payload);
      if (responseData.data && responseData.data.length > 0) {
        let data = responseData.data[0];
        if (id) {
          getTenantDropdownData(responseData.data[0].tenant_id);
          setFetchedData(data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const getTenantDropdownData = async (id) => {
    try {
      let tenantId = localStorage.getItem("tenantId"); // type 1=admin,2=tenant
      let authID = localStorage.getItem("logedin-user"); // type 1=admin,2=tenant
      authID = JSON.parse(authID);
      authID = authID[0];

      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          is_active: 1,
        },
      };
      if (authID.type == 1 && tenantId) {
        // admin
        payload.whereCondition.id = tenantId;
      }
      if (authID.type == 2) {
        // tenant id
        payload.whereCondition.id = authID.tenant_id;
      }

      const responseData = await fetchData("getMasterList", payload);

      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "tenant_company_name"
        );
        if (id) {
          dropdownResponseData[0].options.forEach((element) => {
            if (element.value == id) {
              setSelectedTenant(element);
            }
          });
        }
        setTenantlist(dropdownResponseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectTenantChange = (selectedTenant) => {
    setSelectedTenant(selectedTenant);
    setTenantError(!selectedTenant);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        tenant_id: selectedTenant ? selectedTenant.value : "",
      },
    }));
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    const [mainKey, subKey] = name.split(".");

    setFormData((prevState) => {
      if (subKey) {
        return {
          ...prevState,
          inputData: {
            ...prevState.inputData,
            [mainKey]: {
              ...prevState.inputData[mainKey],
              [subKey]:
                type === "checkbox" ? (checked === true ? 1 : 0) : value,
            },
          },
        };
      } else {
        return {
          ...prevState,
          inputData: {
            ...prevState.inputData,
            [name]: type === "checkbox" ? (checked === true ? 1 : 0) : value,
          },
        };
      }
    });
  };

  const handle_Add_IOS_IBO_Row = () => {
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        landing_screen_ios: [
          ...(prevState.inputData.landing_screen_ios || []),
          {
            // type:prevState.inputData.landing_screen_ios.length+1,
            landing_page_image: "",
            landing_page_title: "",
            landing_page_sub_title: "",
            sequence: "",
            landing_page_text: "",
            landing_page_backgroup_color: "",
          },
        ],
      },
    }));
  };

  const handle_Add_IOS_Guest_Row = () => {
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        guest_landing_screen_ios: [
          ...(prevState.inputData.guest_landing_screen_ios || []),
          {
            landing_page_image: "",
            landing_page_title: "",
            landing_page_sub_title: "",
            sequence: "",
            landing_page_text: "",
            landing_page_backgroup_color: "",
          },
        ],
      },
    }));
  };

  const handle_Add_Android_IBO_Row = () => {
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        landing_screen: [
          ...(prevState.inputData.landing_screen || []),
          {
            // type: prevState.inputData.landing_screen.length + 1,
            landing_page_image: "",
            landing_page_title: "",
            landing_page_sub_title: "",
            sequence: "",
            landing_page_text: "",
            landing_page_backgroup_color: "",
          },
        ],
      },
    }));
  };

  const handle_Add_Android_Guest_Row = () => {
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        guest_landing_screen: [
          ...(prevState.inputData.guest_landing_screen || []),
          {
            landing_page_image: "",
            landing_page_title: "",
            landing_page_sub_title: "",
            sequence: "",
            landing_page_text: "",
            landing_page_backgroup_color: "",
          },
        ],
      },
    }));
  };

  const handle_Delete_IOS_IBO_Row = (index) => {
    if (fileInputRefs.current[index]) {
      fileInputRefs.current[index].value = "";
    }

    const updatedLandingIOSPageIBOImages = [...landingIOSPageIBOImages];
    updatedLandingIOSPageIBOImages.splice(index, 1);
    setLandingIOSPageIBOImages(updatedLandingIOSPageIBOImages);

    setFormData((prevState) => {
      const updatedUploadImage = prevState.uploadImage.filter(
        (image) =>
          image.field_name !== `landing_screen_ios[${index}].landing_page_image`
      );

      const adjustedUploadImage = updatedUploadImage.map((image) => {
        const match = image.field_name.match(/^landing_screen_ios\[(\d+)\]/);
        if (match && parseInt(match[1], 10) > index) {
          const newIndex = parseInt(match[1], 10) - 1;
          return {
            ...image,
            field_name: `landing_screen_ios[${newIndex}].landing_page_image`,
          };
        }
        return image;
      });

      const updatedLandingScreenIOSData = [
        ...prevState.inputData.landing_screen_ios,
      ];
      updatedLandingScreenIOSData.splice(index, 1);

      return {
        ...prevState,
        inputData: {
          ...prevState.inputData,
          landing_screen_ios: updatedLandingScreenIOSData,
        },
        uploadImage: adjustedUploadImage,
      };
    });
  };

  const handle_Delete_IOS_Guest_Row = (index) => {
    if (fileInputGuestRefs.current[index]) {
      fileInputGuestRefs.current[index].value = "";
    }

    const updatedLandingIOSPageIBOImages = [...landingIOSPageGuestImages];
    updatedLandingIOSPageIBOImages.splice(index, 1);
    setLandingIOSPageGuestImages(updatedLandingIOSPageIBOImages);

    setFormData((prevState) => {
      const updatedUploadImage = prevState.uploadImage.filter(
        (image) =>
          image.field_name !==
          `guest_landing_screen_ios[${index}].landing_page_image`
      );

      const adjustedUploadImage = updatedUploadImage.map((image) => {
        const match = image.field_name.match(
          /^guest_landing_screen_ios\[(\d+)\]/
        );
        if (match && parseInt(match[1], 10) > index) {
          const newIndex = parseInt(match[1], 10) - 1;
          return {
            ...image,
            field_name: `guest_landing_screen_ios[${newIndex}].landing_page_image`,
          };
        }
        return image;
      });

      const updatedLandingScreenIOSData = [
        ...prevState.inputData.guest_landing_screen_ios,
      ];
      updatedLandingScreenIOSData.splice(index, 1);

      return {
        ...prevState,
        inputData: {
          ...prevState.inputData,
          guest_landing_screen_ios: updatedLandingScreenIOSData,
        },
        uploadImage: adjustedUploadImage,
      };
    });
  };

  const handle_Delete_Android_IBO_Row = (index) => {
    if (fileInputRefs.current[index]) {
      fileInputRefs.current[index].value = "";
    }

    const updatedLandingIOSPageIBOImages = [...landingAndroidPageIBOImages];
    updatedLandingIOSPageIBOImages.splice(index, 1);
    setLandingAndroidPageIBOImages(updatedLandingIOSPageIBOImages);

    setFormData((prevState) => {
      const updatedUploadImage = prevState.uploadImage.filter(
        (image) =>
          image.field_name !== `landing_screen[${index}].landing_page_image`
      );

      const adjustedUploadImage = updatedUploadImage.map((image) => {
        const match = image.field_name.match(/^landing_screen\[(\d+)\]/);
        if (match && parseInt(match[1], 10) > index) {
          const newIndex = parseInt(match[1], 10) - 1;
          return {
            ...image,
            field_name: `landing_screen[${newIndex}].landing_page_image`,
          };
        }
        return image;
      });

      const updatedLandingScreenIOSData = [
        ...prevState.inputData.landing_screen,
      ];
      updatedLandingScreenIOSData.splice(index, 1);

      return {
        ...prevState,
        inputData: {
          ...prevState.inputData,
          landing_screen: updatedLandingScreenIOSData,
        },
        uploadImage: adjustedUploadImage,
      };
    });
  };

  const handle_Delete_Android_Guest_Row = (index) => {
    if (fileInputGuestRefs.current[index]) {
      fileInputGuestRefs.current[index].value = "";
    }

    const updatedLandingIOSPageIBOImages = [...landingAndroidPageGuestImages];
    updatedLandingIOSPageIBOImages.splice(index, 1);
    setLandingAndroidPageGuestImages(updatedLandingIOSPageIBOImages);

    setFormData((prevState) => {
      const updatedUploadImage = prevState.uploadImage.filter(
        (image) =>
          image.field_name !==
          `guest_landing_screen[${index}].landing_page_image`
      );

      const adjustedUploadImage = updatedUploadImage.map((image) => {
        const match = image.field_name.match(/^guest_landing_screen\[(\d+)\]/);
        if (match && parseInt(match[1], 10) > index) {
          const newIndex = parseInt(match[1], 10) - 1;
          return {
            ...image,
            field_name: `guest_landing_screen[${newIndex}].landing_page_image`,
          };
        }
        return image;
      });

      const updatedLandingScreenIOSData = [
        ...prevState.inputData.guest_landing_screen,
      ];
      updatedLandingScreenIOSData.splice(index, 1);

      return {
        ...prevState,
        inputData: {
          ...prevState.inputData,
          guest_landing_screen: updatedLandingScreenIOSData,
        },
        uploadImage: adjustedUploadImage,
      };
    });
  };

  const handle_IBO_Multi_Module_FileChange = (e, index, type) => {
    const file = e.target.files[0];

    if (!file) {
      setFormData((prevState) => {
        const fieldName =
          type === "ios"
            ? `landing_screen_ios[${index}].landing_page_image`
            : `landing_screen[${index}].landing_page_image`;

        const updatedUploadImage = prevState.uploadImage.filter(
          (image) => image.field_name !== fieldName
        );

        return {
          ...prevState,
          uploadImage: updatedUploadImage,
        };
      });

      if (type === "ios") {
        setLandingIOSPageIBOImages((prevImages) => {
          const newImages = [...prevImages];
          newImages[index] = null;
          return newImages;
        });
      } else if (type === "android") {
        setLandingAndroidPageIBOImages((prevImages) => {
          const newImages = [...prevImages];
          newImages[index] = null;
          return newImages;
        });
      }

      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const newImagePath = reader.result;

      setFormData((prevState) => {
        const fieldName =
          type === "ios"
            ? `landing_screen_ios[${index}].landing_page_image`
            : `landing_screen[${index}].landing_page_image`;

        const existingImageIndex = prevState.uploadImage.findIndex(
          (image) => image.field_name === fieldName
        );

        const newUploadImage =
          existingImageIndex >= 0
            ? prevState.uploadImage.map((image, i) =>
                i === existingImageIndex
                  ? { ...image, imagePath: newImagePath }
                  : image
              )
            : [
                ...prevState.uploadImage,
                {
                  field_name: fieldName,
                  folderPath: "",
                  imagePath: newImagePath,
                },
              ];

        return {
          ...prevState,
          uploadImage: newUploadImage,
        };
      });

      if (type === "ios") {
        setLandingIOSPageIBOImages((prevImages) => {
          const newImages = [...prevImages];
          newImages[index] = newImagePath;
          return newImages;
        });
      } else if (type === "android") {
        setLandingAndroidPageIBOImages((prevImages) => {
          const newImages = [...prevImages];
          newImages[index] = newImagePath;
          return newImages;
        });
      }
    };

    reader.readAsDataURL(file);
  };

  const handle_Guest_Multi_Module_FileChange = (e, index, type) => {
    const file = e.target.files[0];

    if (!file) {
      setFormData((prevState) => {
        const fieldName =
          type === "ios"
            ? `guest_landing_screen_ios[${index}].landing_page_image`
            : `guest_landing_screen[${index}].landing_page_image`;

        const updatedUploadImage = prevState.uploadImage.filter(
          (image) => image.field_name !== fieldName
        );

        return {
          ...prevState,
          uploadImage: updatedUploadImage,
        };
      });

      if (type === "ios") {
        setLandingIOSPageGuestImages((prevImages) => {
          const newImages = [...prevImages];
          newImages[index] = null;
          return newImages;
        });
      } else if (type === "android") {
        setLandingAndroidPageGuestImages((prevImages) => {
          const newImages = [...prevImages];
          newImages[index] = null;
          return newImages;
        });
      }

      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const newImagePath = reader.result;

      setFormData((prevState) => {
        const fieldName =
          type === "ios"
            ? `guest_landing_screen_ios[${index}].landing_page_image`
            : `guest_landing_screen[${index}].landing_page_image`;

        const existingImageIndex = prevState.uploadImage.findIndex(
          (image) => image.field_name === fieldName
        );

        const newUploadImage =
          existingImageIndex >= 0
            ? prevState.uploadImage.map((image, i) =>
                i === existingImageIndex
                  ? { ...image, imagePath: newImagePath }
                  : image
              )
            : [
                ...prevState.uploadImage,
                {
                  field_name: fieldName,
                  folderPath: "",
                  imagePath: newImagePath,
                },
              ];

        return {
          ...prevState,
          uploadImage: newUploadImage,
        };
      });

      if (type === "ios") {
        setLandingIOSPageGuestImages((prevImages) => {
          const newImages = [...prevImages];
          newImages[index] = newImagePath;
          return newImages;
        });
      } else if (type === "android") {
        setLandingAndroidPageGuestImages((prevImages) => {
          const newImages = [...prevImages];
          newImages[index] = newImagePath;
          return newImages;
        });
      }
    };

    reader.readAsDataURL(file);
  };

  const handle_Screen_Logo_FileChange = (e, index, fieldName) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const newImagePath = reader.result;

        let uploadFieldName;
        if (fieldName === "welcome_screen") {
          uploadFieldName = "welcome_screen.welcome_screen";
        } else if (fieldName === "welcome_logo") {
          uploadFieldName = "welcome_screen.welcome_logo";
        } else if (fieldName === "welcome_screen_ios.welcome_screen") {
          uploadFieldName = "welcome_screen_ios.welcome_screen";
        } else if (fieldName === "welcome_screen_ios.welcome_logo") {
          uploadFieldName = "welcome_screen_ios.welcome_logo";
        } else if (
          fieldName === "guest_welcome_screen_ios.guest_welcome_logo"
        ) {
          uploadFieldName = "guest_welcome_screen_ios.guest_welcome_logo";
        } else if (
          fieldName === "guest_welcome_screen_ios.guest_welcome_screen"
        ) {
          uploadFieldName = "guest_welcome_screen_ios.guest_welcome_screen";
        } else if (fieldName === "guest_welcome_logo") {
          uploadFieldName = "guest_welcome_screen.guest_welcome_logo";
        } else if (fieldName === "guest_welcome_screen") {
          uploadFieldName = "guest_welcome_screen.guest_welcome_screen";
        }

        setFormData((prevState) => {
          const existingImageIndex = prevState.uploadImage.findIndex(
            (image) => image.field_name === uploadFieldName
          );

          const newUploadImage =
            existingImageIndex >= 0
              ? prevState.uploadImage.map((image, i) =>
                  i === existingImageIndex
                    ? { ...image, imagePath: newImagePath }
                    : image
                )
              : [
                  ...prevState.uploadImage,
                  {
                    field_name: uploadFieldName,
                    folderPath: "",
                    imagePath: newImagePath,
                  },
                ];

          if (fieldName === "welcome_screen") {
            setWelcomeScreenAndroidImg(newImagePath);
            setIsMediaRemoveAndDownloadAndroidScreen(true);
          } else if (fieldName === "welcome_logo") {
            setWelcomeLogoAndroidImg(newImagePath);
            setIsMediaRemoveAndDownloadAndroidLogo(true);
          } else if (fieldName === "welcome_screen_ios.welcome_screen") {
            setWelcomeScreenIOSImg(newImagePath);
            setIsMediaRemoveAndDownloadIosScreen(true);
          } else if (fieldName === "welcome_screen_ios.welcome_logo") {
            setWelcomeLogoIOSLImg(newImagePath);
            setIsMediaRemoveAndDownloadIosLogo(true);
          } else if (
            fieldName === "guest_welcome_screen_ios.guest_welcome_logo"
          ) {
            setGuestWelcomeLogoIOSImg(newImagePath);
            setIsMediaRemoveAndDownloadIosGuestLogo(true);
          } else if (
            fieldName === "guest_welcome_screen_ios.guest_welcome_screen"
          ) {
            setGuestWelcomeScreenIOSImg(newImagePath);
            setIsMediaRemoveAndDownloadIosGuestScreen(true);
          } else if (fieldName === "guest_welcome_logo") {
            setGuestWelcomeLogoAndroidImg(newImagePath);
            setIsMediaRemoveAndDownloadAndroidGuestLogo(true);
          } else if (fieldName === "guest_welcome_screen") {
            setGuestWelcomeScreenAndroidImg(newImagePath);
            setIsMediaRemoveAndDownloadAndroidGuestScreen(true);
          }

          return {
            ...prevState,
            uploadImage: newUploadImage,
          };
        });
      };

      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e, index, field, type, parent) => {
    console.log(parent);
    const { value } = e.target;

    if (type === "ios") {
      if (parent === "ibo") {
        setFormData((prevState) => {
          const updatedIBOLandingScreen = [
            ...prevState.inputData.landing_screen_ios,
          ];
          updatedIBOLandingScreen[index][field] = value;
          return {
            ...prevState,
            inputData: {
              ...prevState.inputData,
              landing_screen_ios: updatedIBOLandingScreen,
            },
          };
        });
      } else if (parent === "guest") {
        setFormData((prevState) => {
          const updatedGuestLandingScreen = [
            ...prevState.inputData.guest_landing_screen_ios,
          ];
          updatedGuestLandingScreen[index][field] = value;
          return {
            ...prevState,
            inputData: {
              ...prevState.inputData,
              guest_landing_screen_ios: updatedGuestLandingScreen,
            },
          };
        });
      }
    } else if (type === "android") {
      if (parent === "ibo") {
        setFormData((prevState) => {
          const updatedIBOLandingScreen = [
            ...prevState.inputData.landing_screen,
          ];
          updatedIBOLandingScreen[index][field] = value;
          return {
            ...prevState,
            inputData: {
              ...prevState.inputData,
              landing_screen: updatedIBOLandingScreen,
            },
          };
        });
      } else if (parent === "guest") {
        setFormData((prevState) => {
          const updatedGuestLandingScreen = [
            ...prevState.inputData.guest_landing_screen,
          ];
          updatedGuestLandingScreen[index][field] = value;
          return {
            ...prevState,
            inputData: {
              ...prevState.inputData,
              guest_landing_screen: updatedGuestLandingScreen,
            },
          };
        });
      }
    }
  };

  // handle media download
  const handleDownloadMediaClick = async (mediaFile, fieldName) => {
    if (fieldName === "welcome_screen_ios.welcome_screen") {
      try {
        setIosWelcomeScreenLoadingData(true);
        const urlSegments = mediaFile.split("/");
        const lastSegment = urlSegments[urlSegments.length - 1];
        await downloadBlobData("", lastSegment);
      } catch (error) {
        console.error("Error downloading the file:", error);
      } finally {
        setIosWelcomeScreenLoadingData(false);
      }
    } else if (fieldName === "welcome_screen_ios.welcome_logo") {
      try {
        setIosWelcomeLogoLoadingData(true);
        const urlSegments = mediaFile.split("/");
        const lastSegment = urlSegments[urlSegments.length - 1];
        await downloadBlobData("", lastSegment);
      } catch (error) {
        console.error("Error downloading the file:", error);
      } finally {
        setIosWelcomeLogoLoadingData(false);
      }
    } else if (fieldName === "guest_welcome_screen_ios.guest_welcome_screen") {
      try {
        setIosGuestWelcomeLoadingData(true);
        const urlSegments = mediaFile.split("/");
        const lastSegment = urlSegments[urlSegments.length - 1];
        await downloadBlobData("", lastSegment);
      } catch (error) {
        console.error("Error downloading the file:", error);
      } finally {
        setIosGuestWelcomeLoadingData(false);
      }
    } else if (
      fieldName === "guest_welcome_screen_ios.guest_welcome_screen_logo"
    ) {
      try {
        setIosGuestWelcomeLogoLoadingData(true);
        const urlSegments = mediaFile.split("/");
        const lastSegment = urlSegments[urlSegments.length - 1];
        await downloadBlobData("", lastSegment);
      } catch (error) {
        console.error("Error downloading the file:", error);
      } finally {
        setIosGuestWelcomeLogoLoadingData(false);
      }
    } else if (fieldName === "welcome_screen") {
      try {
        setAndroidsWelcomeScreenLoadingData(true);
        const urlSegments = mediaFile.split("/");
        const lastSegment = urlSegments[urlSegments.length - 1];
        await downloadBlobData("", lastSegment);
      } catch (error) {
        console.error("Error downloading the file:", error);
      } finally {
        setAndroidsWelcomeScreenLoadingData(false);
      }
    } else if (fieldName === "welcome_logo") {
      try {
        setAndroidsWelcomeLogoLoadingData(true);
        const urlSegments = mediaFile.split("/");
        const lastSegment = urlSegments[urlSegments.length - 1];
        await downloadBlobData("", lastSegment);
      } catch (error) {
        console.error("Error downloading the file:", error);
      } finally {
        setAndroidsWelcomeLogoLoadingData(false);
      }
    } else if (fieldName === "guest_welcome_screen") {
      try {
        setAndroidGuestWelcomeScreenLoadingData(true);
        const urlSegments = mediaFile.split("/");
        const lastSegment = urlSegments[urlSegments.length - 1];
        await downloadBlobData("", lastSegment);
      } catch (error) {
        console.error("Error downloading the file:", error);
      } finally {
        setAndroidGuestWelcomeScreenLoadingData(false);
      }
    } else if (fieldName === "guest_welcome_logo") {
      try {
        setAndroidGuestWelcomeLogoLoadingData(true);
        const urlSegments = mediaFile.split("/");
        const lastSegment = urlSegments[urlSegments.length - 1];
        await downloadBlobData("", lastSegment);
      } catch (error) {
        console.error("Error downloading the file:", error);
      } finally {
        setAndroidGuestWelcomeLogoLoadingData(false);
      }
    }
  };

  // handle media remove
  const handleRemoveMediaClick = (fieldName) => {
    if (fieldName === "welcome_screen") {
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          welcome_screen: {
            ...prevState.inputData.welcome_screen,
            welcome_screen: "",
          },
        },
      }));
      setWelcomeScreenAndroidImg("");
    } else if (fieldName === "welcome_logo") {
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          welcome_screen: {
            ...prevState.inputData.welcome_screen,
            welcome_logo: "",
          },
        },
      }));
      setWelcomeLogoAndroidImg("");
    } else if (fieldName === "welcome_screen_ios.welcome_screen") {
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          welcome_screen_ios: {
            ...prevState.inputData.welcome_screen_ios,
            welcome_screen: "",
          },
        },
      }));
      setWelcomeScreenIOSImg("");
    } else if (fieldName === "welcome_screen_ios.welcome_logo") {
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          welcome_screen_ios: {
            ...prevState.inputData.welcome_screen_ios,
            welcome_logo: "",
          },
        },
      }));
      setWelcomeLogoIOSLImg("");
    } else if (fieldName === "guest_welcome_screen_ios.guest_welcome_logo") {
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          guest_welcome_screen_ios: {
            ...prevState.inputData.guest_welcome_screen_ios,
            guest_welcome_logo: "",
          },
        },
      }));
      setGuestWelcomeLogoIOSImg("");
    } else if (fieldName === "guest_welcome_screen_ios.guest_welcome_screen") {
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          guest_welcome_screen_ios: {
            ...prevState.inputData.guest_welcome_screen_ios,
            guest_welcome_screen: "",
          },
        },
      }));

      setGuestWelcomeScreenIOSImg("");
    } else if (fieldName === "guest_welcome_logo") {
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          guest_welcome_screen: {
            ...prevState.inputData.guest_welcome_screen,
            guest_welcome_logo: "",
          },
        },
      }));
      setGuestWelcomeLogoAndroidImg("");
    } else if (fieldName === "guest_welcome_screen") {
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          guest_welcome_screen: {
            ...prevState.inputData.guest_welcome_screen,
            guest_welcome_screen: "",
          },
        },
      }));
      setGuestWelcomeScreenAndroidImg("");
    }
  };

  const validateForm = () => {
    const { google_play_download_url, ios_app_download_url, ios_app_open_url } =
      formData.inputData;

    const imageUrlRegex =
      /^(https?:\/\/(www\.)?|www\.)[^\s/$.?#].[^\s]*\.(jpg|jpeg|png|gif|bmp|svg|webp)(\/[^\s]*)?$/;
    const downloadUrlRegex =
      /^(https?:\/\/(www\.)?|www\.)[^\s/$.?#].[^\s]*\.(zip|exe|dmg|tar|gz|rar|7z|pdf|doc|docx|xls|xlsx|ppt|pptx)(\/[^\s]*)?$/;
    const websiteUrlRegex =
      /^(https?:\/\/(www\.)?|www\.)[^\s/$.?#].[^\s]*\.(com|net|org|gov|edu|io|in|info|biz|co|us)(\/[^\s]*)?$/;

    const urls = [
      {
        name: "ios_app_open_url",
        value: ios_app_open_url,
        regex: websiteUrlRegex,
      },
      {
        name: "google_play_download_url",
        value: google_play_download_url,
        regex: downloadUrlRegex,
      },
      {
        name: "ios_app_download_url",
        value: ios_app_download_url,
        regex: downloadUrlRegex,
      },
    ];
    for (const url of urls) {
      if (!url.regex.test(url.value)) {
        toast.error(
          `Please enter a valid URL for ${url.name.replace(/_/g, " ")}.`
        );
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    !selectedTenant ? setTenantError(true) : setTenantError(false);

    if (
      !selectedTenant ||
      !formData.inputData.app_name ||
      !formData.inputData.ios_app_open_url ||
      !formData.inputData.ios_app_download_url ||
      !formData.inputData.google_play_download_url ||
      !formData.inputData.android_app_id ||
      !formData.inputData.ios_app_id
    ) {
      return toast.warning("All fields are required");
    }

    if (!validateForm()) {
      return;
    }

    try {
      setLoadingData(true);
      const filteredUploadImage = formData.uploadImage.filter(
        (image) => image.imagePath !== ""
      );
      var payload = {
        modelName: "application_settings",
        inputData: {
          ...formData.inputData,
          landing_screen_ios: formData.inputData.landing_screen_ios.map(
            (item, index) => ({ ...item, type: index + 1 })
          ),
          landing_screen: formData.inputData.landing_screen.map(
            (item, index) => ({ ...item, type: index + 1 })
          ),

          guest_landing_screen_ios:
            formData.inputData.guest_landing_screen_ios.map((item, index) => ({
              ...item,
              type: index + 1,
            })),

          guest_landing_screen: formData.inputData.guest_landing_screen.map(
            (item, index) => ({ ...item, type: index + 1 })
          ),
        },
        uploadImage: filteredUploadImage,
      };

      if (id) {
        payload.id = id;
      }

      const responseData = await fetchData("createAndUpdateMaster", payload);
      if (responseData.code == 1) {
        toast.success("Mobile App Setting saved successfully!");
        navigate("/mobile-app-settings-list");
      } else {
        toast.error("Error while saving Mobile App Setting!");
        navigate("/mobile-app-settings-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handelGeneral = () => {
    setgeneralIsVisible(true);
    setIOSsetingIsVisible(false);
    setAndroidIsVisible(false);
  };

  const handelIOSSetting = () => {
    setIOSsetingIsVisible(true);
    setgeneralIsVisible(false);
    setAndroidIsVisible(false);
  };

  const handelAndroid = () => {
    setAndroidIsVisible(true);
    setIOSsetingIsVisible(false);
    setgeneralIsVisible(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE MOBILE APP SETTINGS"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      className="needs-validation"
                      onSubmit={handleSubmit}>
                      <>
                        {" "}
                        <Row className="mt-4 mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}>
                            <h5
                              style={{
                                color: generalIsVisible ? "white" : "gray",

                                backgroundColor: generalIsVisible
                                  ? "gray"
                                  : "white",
                                width: 200,
                                textAlign: "center",
                                borderRadius: "5px",
                                paddingBottom: "3px",
                                cursor: "pointer",
                              }}
                              onClick={handelGeneral}>
                              General{" "}
                            </h5>
                            <h5
                              style={{
                                color: IOSsetingIsVisible ? "white" : "gray",

                                backgroundColor: IOSsetingIsVisible
                                  ? "gray"
                                  : "white",
                                width: 200,
                                textAlign: "center",
                                borderRadius: "5px",
                                paddingBottom: "3px",
                                cursor: "pointer",
                              }}
                              onClick={handelIOSSetting}>
                              IOS setting{" "}
                            </h5>

                            <h5
                              style={{
                                color: androidIsVisible ? "white" : "gray",
                                backgroundColor: androidIsVisible
                                  ? "gray"
                                  : "white",
                                width: 200,
                                textAlign: "center",
                                borderRadius: "5px",
                                paddingBottom: "3px",
                                cursor: "pointer",
                              }}
                              onClick={handelAndroid}>
                              Android setting{" "}
                            </h5>
                          </div>
                        </Row>
                        {generalIsVisible && (
                          <Row
                            style={{
                              border: "1px solid gray",
                              borderRadius: "5px",
                              marginBlock: "5px",
                              paddingTop: "25px",
                              boxShadow: "0px 0px 7px 1px gray",
                            }}>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Tanent
                                  <Asterisk />
                                </Label>
                                <Select
                                  value={selectedTenant}
                                  onChange={handleSelectTenantChange}
                                  options={tenantlist}
                                  classNamePrefix="select2-selection"
                                  noOptionsMessage={() => "no data found.."}
                                  maxMenuHeight={200}
                                  isClearable
                                />
                                {tenantError && (
                                  <span>
                                    <p
                                      style={{
                                        fontSize: "11.5px",
                                        color: "red",
                                      }}>
                                      Select Tenant
                                    </p>
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="app_name">
                                  App name
                                  <Asterisk />
                                </Label>
                                <AvField
                                  name="app_name"
                                  placeholder="App name"
                                  type="text"
                                  errorMessage="Enter App Name"
                                  className="form-control"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  id="app_name"
                                  value={formData.inputData.app_name}
                                  onChange={handleTextChange}
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="google_play_download_url">
                                  Google play download URL
                                  <Asterisk />
                                </Label>

                                <AvField
                                  name="google_play_download_url"
                                  placeholder="Google play download url"
                                  type="url"
                                  errorMessage="Enter google play download url"
                                  className="form-control"
                                  validate={{
                                    required: { value: true },
                                    pattern: {
                                      value: regexDdownloadUrl,
                                      errorMessage: downloadErrorMessage,
                                    },
                                  }}
                                  id="google_play_download_url"
                                  value={
                                    formData.inputData.google_play_download_url
                                  }
                                  onChange={handleTextChange}
                                />
                              </div>
                            </Col>
                            <Row className="mt-4">
                              <Col>
                                <div className="form-check mb-3">
                                  <input
                                    name="social_media_login"
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkbox-1"
                                    required=""
                                    value={
                                      formData.inputData.social_media_login
                                    }
                                    checked={
                                      formData.inputData.social_media_login
                                    }
                                    onChange={handleTextChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox-1">
                                    Social media login
                                  </label>
                                </div>
                              </Col>

                              <Col>
                                <div className="form-check mb-3">
                                  <input
                                    name="set_default_tab"
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkbox-2"
                                    required=""
                                    value={formData.inputData.set_default_tab}
                                    checked={formData.inputData.set_default_tab}
                                    onChange={handleTextChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox-2">
                                    Set default tab
                                  </label>
                                </div>
                              </Col>

                              <Col>
                                <div className="form-check mb-3">
                                  <input
                                    name="guest_signup"
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkbox-3"
                                    required=""
                                    value={formData.inputData.guest_signup}
                                    checked={formData.inputData.guest_signup}
                                    onChange={handleTextChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox-3">
                                    Guest signup
                                  </label>
                                </div>
                              </Col>

                              <Col>
                                <div className="form-check mb-3">
                                  <input
                                    name="default_profile_image_enable"
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkbox-4"
                                    required=""
                                    value={Number(
                                      formData.inputData
                                        .default_profile_image_enable
                                    )}
                                    checked={Number(
                                      formData.inputData
                                        .default_profile_image_enable
                                    )}
                                    onChange={handleTextChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox-4">
                                    Default profile image enabled
                                  </label>
                                </div>
                              </Col>
                            </Row>

                            <Row className="mt-2">
                              <Col
                                md={5}
                                style={{
                                  display: "flex",
                                  gap: 10,
                                  marginBlock: "20px",
                                }}>
                                <Input
                                  name="loading_screen_background_color"
                                  type="color"
                                  defaultValue="#000000"
                                  id="example-color-input"
                                  className="form-control form-control-color w-20"
                                  value={
                                    formData.inputData
                                      .loading_screen_background_color
                                  }
                                  onChange={handleTextChange}
                                />
                                <Label className="col-md-2 col-form-label w-50">
                                  Login screen background color
                                </Label>
                              </Col>
                            </Row>

                            <Row className="mt-2">
                              <Col
                                style={{
                                  display: "flex",
                                  gap: 10,
                                }}>
                                <Input
                                  name="login_button_color"
                                  type="color"
                                  defaultValue="#5664d2"
                                  id="example-color-input2"
                                  className="form-control form-control-color w-20"
                                  value={formData.inputData.login_button_color}
                                  onChange={handleTextChange}
                                />
                                <Label className="col-md-2 col-form-label w-50">
                                  Login button color
                                </Label>
                              </Col>
                            </Row>

                            <Row className="mt-3">
                              <Col lg="12">
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={formData.inputData.is_active}
                                    id="is_active"
                                    name="is_active"
                                    checked={formData.inputData.is_active}
                                    onChange={handleTextChange}
                                    required=""
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="is_active">
                                    Is Active
                                  </label>
                                </div>
                              </Col>
                            </Row>
                          </Row>
                        )}
                        {IOSsetingIsVisible && (
                          <Row
                            style={{
                              border: "1px solid gray",
                              borderRadius: "5px",
                              marginBlock: "5px",
                              paddingTop: "25px",
                              boxShadow: "0px 0px 7px 2px gray",
                            }}>
                            <>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="ios_app_open_url">
                                    IOS app open URL
                                    <Asterisk />
                                  </Label>

                                  <AvField
                                    name="ios_app_open_url"
                                    placeholder="IOS app open url"
                                    type="url"
                                    errorMessage="Enter IOS app open url"
                                    className="form-control"
                                    validate={{
                                      required: { value: true },
                                      pattern: {
                                        value: regexWebsiteUrl,
                                        errorMessage: websiteErrorMessage,
                                      },
                                    }}
                                    id="ios_app_open_url"
                                    value={formData.inputData.ios_app_open_url}
                                    onChange={handleTextChange}
                                  />
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="ios_app_download_url">
                                    IOS app download URL
                                    <Asterisk />
                                  </Label>

                                  <AvField
                                    name="ios_app_download_url"
                                    placeholder="IOS app download url"
                                    type="url"
                                    errorMessage="Enter IOS app download url"
                                    className="form-control"
                                    validate={{
                                      required: { value: true },
                                      pattern: {
                                        value: regexDdownloadUrl,
                                        errorMessage:
                                          "Enter a valid URL starting with 'http://' or 'www.' and ending with a valid domain(zip|exe|pdf|doc|ppt).",
                                      },
                                    }}
                                    id="ios_app_download_url"
                                    value={
                                      formData.inputData.ios_app_download_url
                                    }
                                    onChange={handleTextChange}
                                  />
                                </div>
                              </Col>

                              <Col md="6">
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="ios_app_id">
                                    IOS app id
                                    <Asterisk />
                                  </Label>
                                  <AvField
                                    name="ios_app_id"
                                    placeholder="IOS app id"
                                    type="number"
                                    min="1"
                                    errorMessage="Enter IOS App ID"
                                    className="form-control"
                                    validate={{
                                      required: { value: true },
                                      pattern: {
                                        value: "^[1-9]\\d*$",
                                        errorMessage:
                                          "Please enter value greater than 0",
                                      },
                                    }}
                                    id="ios_app_id"
                                    value={formData.inputData.ios_app_id}
                                    onChange={handleTextChange}
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    flexWrap: "wrap",
                                    gap: "20px",
                                  }}>
                                  <div
                                    style={{
                                      width: "calc(50% - 20px)",
                                      textAlign: "left",
                                    }}>
                                    <h6
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        width: "160px",
                                        backgroundColor: "grey",
                                        color: "white",
                                        paddingBlock: "5px",
                                        paddingLeft: "5px",
                                        marginBottom: "0px",
                                        borderTopRightRadius: "15px",
                                      }}>
                                      IBO Onboarding
                                    </h6>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        border: "1px solid gray",
                                        padding: "10px",
                                        boxSizing: "border-box",
                                        borderTopRightRadius: "10px",
                                        borderBottomLeftRadius: "10px",
                                      }}>
                                      <Row>
                                        <Col md="12" className="mt-2">
                                          <div className="mb-3">
                                            <Label
                                              className="form-label"
                                              htmlFor="welcome_screen_ios.welcome_title">
                                              Welcome Title
                                              <Asterisk />
                                            </Label>
                                            <AvField
                                              name="welcome_screen_ios.welcome_title"
                                              placeholder="Welcome Title"
                                              type="text"
                                              errorMessage="Enter Welcome Title"
                                              className="form-control"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              id="welcome_screen_ios.welcome_title"
                                              value={
                                                formData.inputData
                                                  ?.welcome_screen_ios &&
                                                formData.inputData
                                                  ?.welcome_screen_ios
                                                  ?.welcome_title
                                              }
                                              onChange={handleTextChange}
                                            />
                                          </div>
                                        </Col>

                                        <Col md="12" className="mt-3">
                                          <Label
                                            className="form-label"
                                            htmlFor="validationCustom01">
                                            Welcome Screen
                                          </Label>
                                          <div className="input-group">
                                            <input
                                              name="welcome_screen_ios.welcome_screen"
                                              type="file"
                                              className="form-control"
                                              id="customFile"
                                              onChange={(e) =>
                                                handle_Screen_Logo_FileChange(
                                                  e,
                                                  null,
                                                  "welcome_screen_ios.welcome_screen"
                                                )
                                              }
                                            />
                                          </div>
                                          {welcomeScreenIOSImg && (
                                            <Col md="6" className="mt-1">
                                              <div className="mb-3 d-flex">
                                                <img
                                                  src={welcomeScreenIOSImg}
                                                  alt={`welcome screen`}
                                                  width="120px"
                                                  height="100px"
                                                  style={{
                                                    border: ".5px solid gray",
                                                    borderRadius: "5px",
                                                  }}
                                                />
                                                {!isMediaRemoveAndDownloadIosScreen && (
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      marginLeft: "10px",
                                                    }}>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        gap: "15px",
                                                      }}>
                                                      {iosWelcomeScreenLoadingData ? (
                                                        <LoaderDownloadMedia />
                                                      ) : (
                                                        <img
                                                          src={downloadIcon}
                                                          alt="download"
                                                          width="30px"
                                                          height="30px"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() =>
                                                            handleDownloadMediaClick(
                                                              welcomeScreenIOSImg,
                                                              "welcome_screen_ios.welcome_screen"
                                                            )
                                                          }
                                                        />
                                                      )}
                                                      <img
                                                        src={removeIcon}
                                                        alt="remove"
                                                        width="30px"
                                                        height="30px"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          handleRemoveMediaClick(
                                                            "welcome_screen_ios.welcome_screen"
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </Col>
                                          )}
                                        </Col>

                                        <Col md="12" className="mt-4">
                                          <Label
                                            className="form-label"
                                            htmlFor="validationCustom01">
                                            Welcome Logo
                                          </Label>
                                          <div className="input-group">
                                            <input
                                              name="welcome_screen_ios.welcome_logo"
                                              type="file"
                                              className="form-control"
                                              id="customFile"
                                              onChange={(e) =>
                                                handle_Screen_Logo_FileChange(
                                                  e,
                                                  null,
                                                  "welcome_screen_ios.welcome_logo"
                                                )
                                              }
                                            />
                                          </div>
                                          {welcomeLogoIOSLImg && (
                                            <Col md="6" className="mt-1">
                                              <div className="mb-3 d-flex">
                                                <img
                                                  src={welcomeLogoIOSLImg}
                                                  alt={"welcome logo"}
                                                  width="120px"
                                                  height="100px"
                                                  style={{
                                                    border: ".5px solid gray",
                                                    borderRadius: "5px",
                                                  }}
                                                />
                                                {!isMediaRemoveAndDownloadIosLogo && (
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      marginLeft: "10px",
                                                    }}>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        gap: "15px",
                                                      }}>
                                                      {iosWelcomeLogoLoadingData ? (
                                                        <LoaderDownloadMedia />
                                                      ) : (
                                                        <img
                                                          src={downloadIcon}
                                                          alt="download"
                                                          width="30px"
                                                          height="30px"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() =>
                                                            handleDownloadMediaClick(
                                                              welcomeLogoIOSLImg,
                                                              "welcome_screen_ios.welcome_logo"
                                                            )
                                                          }
                                                        />
                                                      )}
                                                      <img
                                                        src={removeIcon}
                                                        alt="remove"
                                                        width="30px"
                                                        height="30px"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          handleRemoveMediaClick(
                                                            "welcome_screen_ios.welcome_logo"
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </Col>
                                          )}
                                        </Col>

                                        <Col
                                          md="12"
                                          style={{
                                            display: "flex",
                                            gap: 10,
                                            marginBlock: "20px",
                                          }}>
                                          <Input
                                            name="welcome_screen_ios.welcome_background_color"
                                            type="color"
                                            defaultValue="#000000"
                                            id="example-color-input"
                                            className="form-control form-control-color w-20"
                                            value={
                                              formData.inputData
                                                ?.welcome_screen_ios &&
                                              formData.inputData
                                                ?.welcome_screen_ios
                                                ?.welcome_background_color
                                            }
                                            onChange={handleTextChange}
                                          />
                                          <Label className="col-md-2 col-form-label w-50">
                                            Welcome Background Color
                                          </Label>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      width: "calc(50% - 20px)",
                                      textAlign: "left",
                                    }}>
                                    <h6
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        width: "160px",
                                        backgroundColor: "grey",
                                        color: "white",
                                        paddingBlock: "5px",
                                        paddingLeft: "5px",
                                        marginBottom: "0px",
                                        borderTopRightRadius: "15px",
                                      }}>
                                      Guest Onboarding
                                    </h6>

                                    <div
                                      style={{
                                        boxSizing: "border-box",
                                        display: "flex",
                                        flexDirection: "column",
                                        border: "1px solid gray",
                                        borderTopRightRadius: "10px",
                                        borderBottomLeftRadius: "10px",
                                        padding: "10px",
                                      }}>
                                      <Row>
                                        <Col md="12" className="mt-2">
                                          <div className="mb-3">
                                            <Label
                                              className="form-label"
                                              htmlFor="guest_welcome_screen_ios.guest_welcome_title">
                                              Guest Welcome Title
                                              <Asterisk />
                                            </Label>
                                            <AvField
                                              name="guest_welcome_screen_ios.guest_welcome_title"
                                              placeholder="Guest Welcome Title"
                                              type="text"
                                              errorMessage="Enter guest Welcome Title"
                                              className="form-control"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              id="guest_welcome_screen_ios.guest_welcome_title"
                                              value={
                                                formData.inputData
                                                  ?.guest_welcome_screen_ios &&
                                                formData.inputData
                                                  ?.guest_welcome_screen_ios
                                                  ?.guest_welcome_title
                                              }
                                              onChange={handleTextChange}
                                            />
                                          </div>
                                        </Col>

                                        <Col md="12" className="mt-3">
                                          <Label
                                            className="form-label"
                                            htmlFor="validationCustom01">
                                            Guest Welcome Screen
                                          </Label>
                                          <div className="input-group">
                                            <input
                                              name="guest_welcome_screen_ios.guest_welcome_screen"
                                              type="file"
                                              className="form-control"
                                              id="customFile"
                                              onChange={(e) =>
                                                handle_Screen_Logo_FileChange(
                                                  e,
                                                  null,
                                                  "guest_welcome_screen_ios.guest_welcome_screen"
                                                )
                                              }
                                            />
                                          </div>
                                          {guestWelcomeScreenIOSImg && (
                                            <Col md="6" className="mt-1">
                                              <div className="mb-3 d-flex">
                                                <img
                                                  src={guestWelcomeScreenIOSImg}
                                                  alt={`welcome screen`}
                                                  width="120px"
                                                  height="100px"
                                                  style={{
                                                    border: ".5px solid gray",
                                                    borderRadius: "5px",
                                                  }}
                                                />
                                                {!isMediaRemoveAndDownloadIosGuestScreen && (
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      marginLeft: "10px",
                                                    }}>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        gap: "15px",
                                                      }}>
                                                      {iosGuestWelcomeLoadingData ? (
                                                        <LoaderDownloadMedia />
                                                      ) : (
                                                        <img
                                                          src={downloadIcon}
                                                          alt="download"
                                                          width="30px"
                                                          height="30px"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() =>
                                                            handleDownloadMediaClick(
                                                              guestWelcomeScreenIOSImg,
                                                              "guest_welcome_screen_ios.guest_welcome_screen"
                                                            )
                                                          }
                                                        />
                                                      )}
                                                      <img
                                                        src={removeIcon}
                                                        alt="remove"
                                                        width="30px"
                                                        height="30px"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          handleRemoveMediaClick(
                                                            "guest_welcome_screen_ios.guest_welcome_screen"
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </Col>
                                          )}
                                        </Col>

                                        <Col md="12" className="mt-4">
                                          <Label
                                            className="form-label"
                                            htmlFor="validationCustom01">
                                            Guest Welcome Logo
                                          </Label>
                                          <div className="input-group">
                                            <input
                                              name="guest_welcome_screen_ios.guest_welcome_logo"
                                              type="file"
                                              className="form-control"
                                              id="customFile"
                                              onChange={(e) =>
                                                handle_Screen_Logo_FileChange(
                                                  e,
                                                  null,
                                                  "guest_welcome_screen_ios.guest_welcome_logo"
                                                )
                                              }
                                            />
                                          </div>
                                          {guestWelcomeLogoIOSImg && (
                                            <Col md="6" className="mt-1">
                                              <div className="mb-3 d-flex">
                                                <img
                                                  src={guestWelcomeLogoIOSImg}
                                                  alt={"welcome logo"}
                                                  width="120px"
                                                  height="100px"
                                                  style={{
                                                    border: ".5px solid gray",
                                                    borderRadius: "5px",
                                                  }}
                                                />
                                                {!isMediaRemoveAndDownloadIosGuestLogo && (
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      marginLeft: "10px",
                                                    }}>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        gap: "15px",
                                                      }}>
                                                      {iosGuestWelcomeLogoLoadingData ? (
                                                        <LoaderDownloadMedia />
                                                      ) : (
                                                        <img
                                                          src={downloadIcon}
                                                          alt="download"
                                                          width="30px"
                                                          height="30px"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() =>
                                                            handleDownloadMediaClick(
                                                              guestWelcomeLogoIOSImg,
                                                              "guest_welcome_screen_ios.guest_welcome_screen_logo"
                                                            )
                                                          }
                                                        />
                                                      )}
                                                      <img
                                                        src={removeIcon}
                                                        alt="remove"
                                                        width="30px"
                                                        height="30px"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          handleRemoveMediaClick(
                                                            "guest_welcome_screen_ios.guest_welcome_logo"
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </Col>
                                          )}
                                        </Col>

                                        <Col
                                          md="12"
                                          style={{
                                            display: "flex",
                                            gap: 10,
                                            marginBlock: "20px",
                                          }}>
                                          <Input
                                            name="guest_welcome_screen_ios.guest_welcome_background_color"
                                            type="color"
                                            defaultValue="#000000"
                                            id="example-color-input"
                                            className="form-control form-control-color w-20"
                                            value={
                                              formData.inputData
                                                ?.guest_welcome_screen_ios &&
                                              formData.inputData
                                                ?.guest_welcome_screen_ios
                                                ?.guest_welcome_background_color
                                            }
                                            onChange={handleTextChange}
                                          />
                                          <Label className="col-md-2 col-form-label w-50">
                                            Guest Welcome Background Color
                                          </Label>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </>

                            <Row className="mt-4">
                              <Row>
                                <Col style={{ marginBlock: "20px" }}>
                                  <Button
                                    color="primary"
                                    className="waves-effect waves-light me-1 w-10"
                                    onClick={handle_Add_IOS_IBO_Row}>
                                    Add
                                  </Button>
                                </Col>
                              </Row>
                              <Col>
                                <div className="table-responsive">
                                  <h6
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      width: "160px",
                                      backgroundColor: "grey",
                                      color: "white",
                                      paddingBlock: "5px",
                                      paddingLeft: "5px",
                                      marginBottom: "0px",
                                      borderTopRightRadius: "15px",
                                    }}>
                                    IBO Onboarding
                                  </h6>

                                  <table className="table table-responsive table-bordered">
                                    <thead
                                      style={{ border: ".7px solid #bfbfbf" }}>
                                      <tr>
                                        <th>Sr. No</th>
                                        <th>Landing Page Image</th>
                                        <th>Landing Page Title</th>
                                        <th>Landing Page Sub Title</th>
                                        <th>Background Color</th>
                                        <th>Sequence</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {formData.inputData?.landing_screen_ios &&
                                        formData.inputData?.landing_screen_ios?.map(
                                          (row, index) => (
                                            <tr
                                              key={index}
                                              style={{
                                                border: ".5px solid #bfbfbf",
                                              }}>
                                              <td>{index + 1}</td>
                                              <td>
                                                <input
                                                  type="file"
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    handle_IBO_Multi_Module_FileChange(
                                                      e,
                                                      index,
                                                      "ios"
                                                    )
                                                  }
                                                  ref={(el) =>
                                                    (fileInputRefs.current[
                                                      index
                                                    ] = el)
                                                  }
                                                />
                                                {landingIOSPageIBOImages[
                                                  index
                                                ] && (
                                                  <Col md="6" className="mt-1">
                                                    <img
                                                      src={
                                                        landingIOSPageIBOImages[
                                                          index
                                                        ]
                                                      }
                                                      alt={`Landing page ${
                                                        index + 1
                                                      }`}
                                                      width="120px"
                                                      height="100px"
                                                      style={{
                                                        border:
                                                          ".5px solid gray",
                                                        borderRadius: "5px",
                                                      }}
                                                    />
                                                  </Col>
                                                )}
                                              </td>
                                              <td>
                                                <Input
                                                  placeholder="Landing page title"
                                                  type="text"
                                                  className="form-control"
                                                  value={row.landing_page_title}
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      index,
                                                      "landing_page_title",
                                                      "ios",
                                                      "ibo"
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <Input
                                                  placeholder="Landing page sub title"
                                                  type="text"
                                                  className="form-control"
                                                  value={
                                                    row.landing_page_sub_title
                                                  }
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      index,
                                                      "landing_page_sub_title",
                                                      "ios",
                                                      "ibo"
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}>
                                                <Input
                                                  type="color"
                                                  id={`color-input-${index}`}
                                                  className="form-control form-control-color w-10"
                                                  value={
                                                    row.landing_page_backgroup_color
                                                  }
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      index,
                                                      "landing_page_backgroup_color",
                                                      "ios",
                                                      "ibo"
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <Input
                                                  placeholder="sequence"
                                                  type="number"
                                                  className="form-control"
                                                  min="1"
                                                  value={row.sequence}
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      index,
                                                      "sequence",
                                                      "ios",
                                                      "ibo"
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <Button
                                                  color="danger"
                                                  onClick={() =>
                                                    handle_Delete_IOS_IBO_Row(
                                                      index
                                                    )
                                                  }>
                                                  <i class="fas fa-trash-alt"></i>
                                                </Button>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </Col>
                            </Row>

                            <Row className="mt-4">
                              <Row>
                                <Col style={{ marginBlock: "20px" }}>
                                  <Button
                                    color="primary"
                                    className="waves-effect waves-light me-1 w-10"
                                    onClick={handle_Add_IOS_Guest_Row}>
                                    Add
                                  </Button>
                                </Col>
                              </Row>
                              <Col>
                                <div className="table-responsive">
                                  <h6
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      width: "160px",
                                      backgroundColor: "grey",
                                      color: "white",
                                      paddingBlock: "5px",
                                      paddingLeft: "5px",
                                      marginBottom: "0px",
                                      borderTopRightRadius: "15px",
                                    }}>
                                    Guest Onboarding
                                  </h6>
                                  <table className="table table-bordered">
                                    <thead
                                      style={{ border: ".7px solid #bfbfbf" }}>
                                      <tr>
                                        <th>Sr. No</th>
                                        <th>Landing Page Image</th>
                                        <th>Landing Page Title</th>
                                        <th>Landing Page Sub Title</th>
                                        <th>Background Color</th>
                                        <th>Sequence</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {formData.inputData
                                        ?.guest_landing_screen_ios &&
                                        formData.inputData?.guest_landing_screen_ios?.map(
                                          (row, index) => (
                                            <tr
                                              key={index}
                                              style={{
                                                border: ".5px solid #bfbfbf",
                                              }}>
                                              <td>{index + 1}</td>
                                              <td>
                                                <input
                                                  type="file"
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    handle_Guest_Multi_Module_FileChange(
                                                      e,
                                                      index,
                                                      "ios"
                                                    )
                                                  }
                                                  ref={(el) =>
                                                    (fileInputGuestRefs.current[
                                                      index
                                                    ] = el)
                                                  }
                                                />
                                                {landingIOSPageGuestImages[
                                                  index
                                                ] && (
                                                  <Col md="6" className="mt-1">
                                                    <img
                                                      src={
                                                        landingIOSPageGuestImages[
                                                          index
                                                        ]
                                                      }
                                                      alt={`Landing page ${
                                                        index + 1
                                                      }`}
                                                      width="120px"
                                                      height="100px"
                                                      style={{
                                                        border:
                                                          ".5px solid gray",
                                                        borderRadius: "5px",
                                                      }}
                                                    />
                                                  </Col>
                                                )}
                                              </td>
                                              <td>
                                                <Input
                                                  placeholder="Landing page title"
                                                  type="text"
                                                  className="form-control"
                                                  value={row.landing_page_title}
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      index,
                                                      "landing_page_title",
                                                      "ios",
                                                      "guest"
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <Input
                                                  placeholder="Landing page sub title"
                                                  type="text"
                                                  className="form-control"
                                                  value={
                                                    row.landing_page_sub_title
                                                  }
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      index,
                                                      "landing_page_sub_title",
                                                      "ios",
                                                      "guest"
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}>
                                                <Input
                                                  type="color"
                                                  id={`color-input-${index}`}
                                                  className="form-control form-control-color w-10"
                                                  value={
                                                    row.landing_page_backgroup_color
                                                  }
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      index,
                                                      "landing_page_backgroup_color",
                                                      "ios",
                                                      "guest"
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <Input
                                                  placeholder="sequence"
                                                  type="number"
                                                  className="form-control"
                                                  min="1"
                                                  value={row.sequence}
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      index,
                                                      "sequence",
                                                      "ios",
                                                      "guest"
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <Button
                                                  color="danger"
                                                  onClick={() =>
                                                    handle_Delete_IOS_Guest_Row(
                                                      index
                                                    )
                                                  }>
                                                  <i class="fas fa-trash-alt"></i>
                                                </Button>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </Col>
                            </Row>
                          </Row>
                        )}
                        {androidIsVisible && (
                          <Row
                            style={{
                              border: "1px solid gray",
                              borderRadius: "5px",
                              marginBlock: "5px",
                              paddingTop: "25px",
                              boxShadow: "0px 0px 7px 2px gray",
                            }}>
                            <>
                              <Col md="6">
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="android_app_id">
                                    Android app id
                                    <Asterisk />
                                  </Label>
                                  <AvField
                                    name="android_app_id"
                                    placeholder="Android app id"
                                    type="number"
                                    min="1"
                                    errorMessage="Enter Android App ID"
                                    className="form-control"
                                    validate={{
                                      required: { value: true },
                                      pattern: {
                                        value: "^[1-9]\\d*$",
                                        errorMessage:
                                          "Please enter value greater than 0",
                                      },
                                    }}
                                    id="android_app_id"
                                    value={formData.inputData.android_app_id}
                                    onChange={handleTextChange}
                                  />
                                </div>
                              </Col>

                              <Col lg="12">
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    flexWrap: "wrap",
                                    gap: "20px",
                                  }}>
                                  <div
                                    style={{
                                      width: "calc(50% - 20px)",
                                      textAlign: "left",
                                    }}>
                                    <h6
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        width: "160px",
                                        backgroundColor: "grey",
                                        color: "white",
                                        paddingBlock: "5px",
                                        paddingLeft: "5px",
                                        marginBottom: "0px",
                                        borderTopRightRadius: "15px",
                                      }}>
                                      IBO Onboarding
                                    </h6>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        border: "1px solid gray",
                                        padding: "10px",
                                        boxSizing: "border-box",
                                        borderTopRightRadius: "10px",
                                        borderBottomLeftRadius: "10px",
                                      }}>
                                      <Row>
                                        <Col md="12" className="mt-2">
                                          <div className="mb-3">
                                            <Label
                                              className="form-label"
                                              htmlFor="welcome_screen.welcome_title">
                                              Welcome Title
                                              <Asterisk />
                                            </Label>
                                            <AvField
                                              name="welcome_screen.welcome_title"
                                              placeholder="Welcome Title"
                                              type="text"
                                              errorMessage="Enter Welcome Title"
                                              className="form-control"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              id="welcome_screen.welcome_title"
                                              value={
                                                formData.inputData
                                                  ?.welcome_screen &&
                                                formData.inputData
                                                  ?.welcome_screen
                                                  ?.welcome_title
                                              }
                                              onChange={handleTextChange}
                                            />
                                          </div>
                                        </Col>
                                        <Col md="12" className="mt-3">
                                          <Label
                                            className="form-label"
                                            htmlFor="validationCustom01">
                                            Welcome Screen
                                          </Label>
                                          <div className="input-group">
                                            <input
                                              name="welcome_screen"
                                              type="file"
                                              className="form-control"
                                              id="customFile"
                                              onChange={(e) =>
                                                handle_Screen_Logo_FileChange(
                                                  e,
                                                  null,
                                                  "welcome_screen"
                                                )
                                              }
                                            />
                                          </div>
                                          {welcomeScreenAndroidImg && (
                                            <div className="mt-1">
                                              <div className="mb-3 d-flex">
                                                <img
                                                  src={welcomeScreenAndroidImg}
                                                  alt="welcome screen"
                                                  width="120px"
                                                  height="100px"
                                                  style={{
                                                    border: ".5px solid gray",
                                                    borderRadius: "5px",
                                                  }}
                                                />
                                                {!isMediaRemoveAndDownloadAndroidScreen && (
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      marginLeft: "10px",
                                                    }}>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        gap: "15px",
                                                      }}>
                                                      {androidWelcomeScreenLoadingData ? (
                                                        <LoaderDownloadMedia />
                                                      ) : (
                                                        <img
                                                          src={downloadIcon}
                                                          alt="download"
                                                          width="30px"
                                                          height="30px"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() =>
                                                            handleDownloadMediaClick(
                                                              welcomeScreenAndroidImg,
                                                              "welcome_screen"
                                                            )
                                                          }
                                                        />
                                                      )}
                                                      <img
                                                        src={removeIcon}
                                                        alt="remove"
                                                        width="30px"
                                                        height="30px"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          handleRemoveMediaClick(
                                                            "welcome_screen"
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </Col>
                                        <Col md="12" className="mt-4">
                                          <Label
                                            className="form-label"
                                            htmlFor="validationCustom01">
                                            Welcome Logo
                                          </Label>
                                          <div className="input-group">
                                            <input
                                              name="welcome_logo"
                                              type="file"
                                              className="form-control"
                                              id="customFile"
                                              onChange={(e) =>
                                                handle_Screen_Logo_FileChange(
                                                  e,
                                                  null,
                                                  "welcome_logo"
                                                )
                                              }
                                            />
                                          </div>
                                          {welcomeLogoAndroidImg && (
                                            <div className="mt-1">
                                              <div className="mb-3 d-flex">
                                                <img
                                                  src={welcomeLogoAndroidImg}
                                                  alt="welcome screen"
                                                  width="120px"
                                                  height="100px"
                                                  style={{
                                                    border: ".5px solid gray",
                                                    borderRadius: "5px",
                                                  }}
                                                />
                                                {!isMediaRemoveAndDownloadAndroidLogo && (
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      marginLeft: "10px",
                                                    }}>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        gap: "15px",
                                                      }}>
                                                      {androidWelcomeLogoLoadingData ? (
                                                        <LoaderDownloadMedia />
                                                      ) : (
                                                        <img
                                                          src={downloadIcon}
                                                          alt="download"
                                                          width="30px"
                                                          height="30px"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() =>
                                                            handleDownloadMediaClick(
                                                              welcomeLogoAndroidImg,
                                                              "welcome_logo"
                                                            )
                                                          }
                                                        />
                                                      )}
                                                      <img
                                                        src={removeIcon}
                                                        alt="remove"
                                                        width="30px"
                                                        height="30px"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          handleRemoveMediaClick(
                                                            "welcome_logo"
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </Col>
                                        <Col
                                          md="12"
                                          style={{
                                            display: "flex",
                                            gap: 10,
                                            marginBlock: "20px",
                                          }}>
                                          <Input
                                            name="welcome_screen.welcome_background_color"
                                            type="color"
                                            defaultValue="#000000"
                                            id="example-color-input"
                                            className="form-control form-control-color w-20"
                                            value={
                                              formData.inputData
                                                ?.welcome_screen &&
                                              formData.inputData?.welcome_screen
                                                ?.welcome_background_color
                                            }
                                            onChange={handleTextChange}
                                          />
                                          <Label className="col-md-2 col-form-label w-50">
                                            Welcome Background Color
                                          </Label>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "calc(50% - 20px)",
                                      textAlign: "left",
                                    }}>
                                    <h6
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        width: "160px",
                                        backgroundColor: "grey",
                                        color: "white",
                                        paddingBlock: "5px",
                                        paddingLeft: "5px",
                                        marginBottom: "0px",
                                        borderTopRightRadius: "15px",
                                      }}>
                                      Guest Onboarding
                                    </h6>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        border: "1px solid gray",
                                        padding: "10px",
                                        boxSizing: "border-box",
                                        borderTopRightRadius: "10px",
                                        borderBottomLeftRadius: "10px",
                                      }}>
                                      <Row>
                                        <Col md="12" className="mt-2">
                                          <div className="mb-3">
                                            <Label
                                              className="form-label"
                                              htmlFor="guest_welcome_screen.guest_welcome_title">
                                              Guest Welcome Title
                                              <Asterisk />
                                            </Label>
                                            <AvField
                                              name="guest_welcome_screen.guest_welcome_title"
                                              placeholder="Guest Welcome Title"
                                              type="text"
                                              errorMessage="Enter Guest Welcome Title"
                                              className="form-control"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              id="guest_welcome_screen.guest_welcome_title"
                                              value={
                                                formData.inputData
                                                  ?.guest_welcome_screen &&
                                                formData.inputData
                                                  ?.guest_welcome_screen
                                                  ?.guest_welcome_title
                                              }
                                              onChange={handleTextChange}
                                            />
                                          </div>
                                        </Col>
                                        <Col md="12" className="mt-3">
                                          <Label
                                            className="form-label"
                                            htmlFor="validationCustom01">
                                            Guest Welcome Screen
                                          </Label>
                                          <div className="input-group">
                                            <input
                                              name="guest_welcome_screen"
                                              type="file"
                                              className="form-control"
                                              id="customFile"
                                              onChange={(e) =>
                                                handle_Screen_Logo_FileChange(
                                                  e,
                                                  null,
                                                  "guest_welcome_screen"
                                                )
                                              }
                                            />
                                          </div>
                                          {guestWelcomeScreenAndroidImg && (
                                            <div className="mt-1">
                                              <div className="mb-3 d-flex">
                                                <img
                                                  src={
                                                    guestWelcomeScreenAndroidImg
                                                  }
                                                  alt="welcome screen"
                                                  width="120px"
                                                  height="100px"
                                                  style={{
                                                    border: ".5px solid gray",
                                                    borderRadius: "5px",
                                                  }}
                                                />
                                                {!isMediaRemoveAndDownloadAndroidGuestScreen && (
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      marginLeft: "10px",
                                                    }}>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        gap: "15px",
                                                      }}>
                                                      {androidGuestWelcomeScreenLoadingData ? (
                                                        <LoaderDownloadMedia />
                                                      ) : (
                                                        <img
                                                          src={downloadIcon}
                                                          alt="download"
                                                          width="30px"
                                                          height="30px"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() =>
                                                            handleDownloadMediaClick(
                                                              guestWelcomeScreenAndroidImg,
                                                              "guest_welcome_screen"
                                                            )
                                                          }
                                                        />
                                                      )}
                                                      <img
                                                        src={removeIcon}
                                                        alt="remove"
                                                        width="30px"
                                                        height="30px"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          handleRemoveMediaClick(
                                                            "guest_welcome_screen"
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </Col>
                                        <Col md="12" className="mt-4">
                                          <Label
                                            className="form-label"
                                            htmlFor="validationCustom01">
                                            Guest Welcome Logo
                                          </Label>
                                          <div className="input-group">
                                            <input
                                              name="guest_welcome_logo"
                                              type="file"
                                              className="form-control"
                                              id="customFile"
                                              onChange={(e) =>
                                                handle_Screen_Logo_FileChange(
                                                  e,
                                                  null,
                                                  "guest_welcome_logo"
                                                )
                                              }
                                            />
                                          </div>
                                          {guestWelcomeLogoAndroidImg && (
                                            <div className="mt-1">
                                              <div className="mb-3 d-flex">
                                                <img
                                                  src={
                                                    guestWelcomeLogoAndroidImg
                                                  }
                                                  alt="welcome screen"
                                                  width="120px"
                                                  height="100px"
                                                  style={{
                                                    border: ".5px solid gray",
                                                    borderRadius: "5px",
                                                  }}
                                                />
                                                {!isMediaRemoveAndDownloadAndroidGuestLogo && (
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      marginLeft: "10px",
                                                    }}>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        gap: "15px",
                                                      }}>
                                                      {androidGuestWelcomeLogoLoadingData ? (
                                                        <LoaderDownloadMedia />
                                                      ) : (
                                                        <img
                                                          src={downloadIcon}
                                                          alt="download"
                                                          width="30px"
                                                          height="30px"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() =>
                                                            handleDownloadMediaClick(
                                                              guestWelcomeLogoAndroidImg,
                                                              "guest_welcome_logo"
                                                            )
                                                          }
                                                        />
                                                      )}
                                                      <img
                                                        src={removeIcon}
                                                        alt="remove"
                                                        width="30px"
                                                        height="30px"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          handleRemoveMediaClick(
                                                            "guest_welcome_logo"
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </Col>
                                        <Col
                                          md="12"
                                          style={{
                                            display: "flex",
                                            gap: 10,
                                            marginBlock: "20px",
                                          }}>
                                          <Input
                                            name="guest_welcome_screen.guest_welcome_background_color"
                                            type="color"
                                            defaultValue="#000000"
                                            id="example-color-input"
                                            className="form-control form-control-color w-20"
                                            value={
                                              formData.inputData
                                                ?.guest_welcome_screen &&
                                              formData.inputData
                                                ?.guest_welcome_screen
                                                ?.guest_welcome_background_color
                                            }
                                            onChange={handleTextChange}
                                          />
                                          <Label className="col-md-2 col-form-label w-50">
                                            Guest Welcome Background Color
                                          </Label>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </>
                            <Row className="mt-4">
                              <Row>
                                <Col style={{ marginBlock: "20px" }}>
                                  <Button
                                    color="primary"
                                    className="waves-effect waves-light me-1 w-10"
                                    onClick={handle_Add_Android_IBO_Row}>
                                    Add
                                  </Button>
                                </Col>
                              </Row>
                              <Col>
                                <div className="table-responsive">
                                  <h6
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      width: "160px",
                                      backgroundColor: "grey",
                                      color: "white",
                                      paddingBlock: "5px",
                                      paddingLeft: "5px",
                                      marginBottom: "0px",
                                      borderTopRightRadius: "15px",
                                    }}>
                                    IBO Onboarding
                                  </h6>
                                  <table className="table table-bordered">
                                    <thead
                                      style={{ border: ".7px solid #bfbfbf" }}>
                                      <tr>
                                        <th>Sr. No</th>
                                        <th>Landing Page Image</th>
                                        <th>Landing Page Title</th>
                                        <th>Landing Page Sub Title</th>
                                        <th>Background Color</th>
                                        <th>Sequence</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {formData.inputData?.landing_screen &&
                                        formData.inputData?.landing_screen?.map(
                                          (row, index) => (
                                            <tr
                                              key={index}
                                              style={{
                                                border: ".5px solid #bfbfbf",
                                              }}>
                                              <td>{index + 1}</td>
                                              <td>
                                                <input
                                                  type="file"
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    handle_IBO_Multi_Module_FileChange(
                                                      e,
                                                      index,
                                                      "android"
                                                    )
                                                  }
                                                  ref={(el) =>
                                                    (fileInputRefs.current[
                                                      index
                                                    ] = el)
                                                  }
                                                />
                                                {landingAndroidPageIBOImages[
                                                  index
                                                ] && (
                                                  <Col md="6" className="mt-1">
                                                    <img
                                                      src={
                                                        landingAndroidPageIBOImages[
                                                          index
                                                        ]
                                                      }
                                                      alt={`Landing page ${
                                                        index + 1
                                                      }`}
                                                      width="120px"
                                                      height="100px"
                                                      style={{
                                                        border:
                                                          ".5px solid gray",
                                                        borderRadius: "5px",
                                                      }}
                                                    />
                                                  </Col>
                                                )}
                                              </td>
                                              <td>
                                                <Input
                                                  placeholder="Landing page title"
                                                  type="text"
                                                  className="form-control"
                                                  value={row.landing_page_title}
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      index,
                                                      "landing_page_title",
                                                      "android",
                                                      "ibo"
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <Input
                                                  placeholder="Landing page sub title"
                                                  type="text"
                                                  className="form-control"
                                                  value={
                                                    row.landing_page_sub_title
                                                  }
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      index,
                                                      "landing_page_sub_title",
                                                      "android",
                                                      "ibo"
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}>
                                                <Input
                                                  type="color"
                                                  id={`color-input-${index}`}
                                                  className="form-control form-control-color w-10"
                                                  value={
                                                    row.landing_page_backgroup_color
                                                  }
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      index,
                                                      "landing_page_backgroup_color",
                                                      "android",
                                                      "ibo"
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <Input
                                                  placeholder="sequence"
                                                  type="number"
                                                  className="form-control"
                                                  min="1"
                                                  value={row.sequence}
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      index,
                                                      "sequence",
                                                      "android",
                                                      "ibo"
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <Button
                                                  color="danger"
                                                  onClick={() =>
                                                    handle_Delete_Android_IBO_Row(
                                                      index
                                                    )
                                                  }>
                                                  <i class="fas fa-trash-alt"></i>
                                                </Button>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </Col>
                            </Row>

                            <Row className="mt-4">
                              <Row>
                                <Col style={{ marginBlock: "20px" }}>
                                  <Button
                                    color="primary"
                                    className="waves-effect waves-light me-1 w-10"
                                    onClick={handle_Add_Android_Guest_Row}>
                                    Add
                                  </Button>
                                </Col>
                              </Row>
                              <Col>
                                <div className="table-responsive">
                                  <h6
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      width: "160px",
                                      backgroundColor: "grey",
                                      color: "white",
                                      paddingBlock: "5px",
                                      paddingLeft: "5px",
                                      marginBottom: "0px",
                                      borderTopRightRadius: "15px",
                                    }}>
                                    Guest Onboarding
                                  </h6>
                                  <table className="table table-bordered">
                                    <thead
                                      style={{ border: ".7px solid #bfbfbf" }}>
                                      <tr>
                                        <th>Sr. No</th>
                                        <th>Landing Page Image</th>
                                        <th>Landing Page Title</th>
                                        <th>Landing Page Sub Title</th>
                                        <th>Background Color</th>
                                        <th>Sequence</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {formData.inputData
                                        ?.guest_landing_screen &&
                                        formData.inputData?.guest_landing_screen?.map(
                                          (row, index) => (
                                            <tr
                                              key={index}
                                              style={{
                                                border: ".5px solid #bfbfbf",
                                              }}>
                                              <td>{index + 1}</td>
                                              <td>
                                                <input
                                                  type="file"
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    handle_Guest_Multi_Module_FileChange(
                                                      e,
                                                      index,
                                                      "android"
                                                    )
                                                  }
                                                  ref={(el) =>
                                                    (fileInputGuestRefs.current[
                                                      index
                                                    ] = el)
                                                  }
                                                />
                                                {landingAndroidPageGuestImages[
                                                  index
                                                ] && (
                                                  <Col md="6" className="mt-1">
                                                    <img
                                                      src={
                                                        landingAndroidPageGuestImages[
                                                          index
                                                        ]
                                                      }
                                                      alt={`Landing page ${
                                                        index + 1
                                                      }`}
                                                      width="120px"
                                                      height="100px"
                                                      style={{
                                                        border:
                                                          ".5px solid gray",
                                                        borderRadius: "5px",
                                                      }}
                                                    />
                                                  </Col>
                                                )}
                                              </td>
                                              <td>
                                                <Input
                                                  placeholder="Landing page title"
                                                  type="text"
                                                  className="form-control"
                                                  value={row.landing_page_title}
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      index,
                                                      "landing_page_title",
                                                      "android",
                                                      "guest"
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <Input
                                                  placeholder="Landing page sub title"
                                                  type="text"
                                                  className="form-control"
                                                  value={
                                                    row.landing_page_sub_title
                                                  }
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      index,
                                                      "landing_page_sub_title",
                                                      "android",
                                                      "guest"
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}>
                                                <Input
                                                  type="color"
                                                  id={`color-input-${index}`}
                                                  className="form-control form-control-color w-10"
                                                  value={
                                                    row.landing_page_backgroup_color
                                                  }
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      index,
                                                      "landing_page_backgroup_color",
                                                      "android",
                                                      "guest"
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <Input
                                                  placeholder="sequence"
                                                  type="number"
                                                  className="form-control"
                                                  min="1"
                                                  value={row.sequence}
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      index,
                                                      "sequence",
                                                      "android",
                                                      "guest"
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <Button
                                                  color="danger"
                                                  onClick={() =>
                                                    handle_Delete_Android_Guest_Row(
                                                      index
                                                    )
                                                  }>
                                                  <i class="fas fa-trash-alt"></i>
                                                </Button>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </Col>
                            </Row>
                          </Row>
                        )}
                        <div
                          style={{ display: "inline-flex", marginTop: "10px" }}>
                          <Button
                            color="primary"
                            type="submit"
                            className=" waves-effect waves-light me-1">
                            {id ? "Update" : "Submit"}
                          </Button>
                          <Button
                            color="primary"
                            className=" waves-effect waves-light me-1"
                            onClick={() =>
                              navigate("/mobile-app-settings-list")
                            }>
                            Back
                          </Button>
                        </div>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MobileAppSettingsCreate;
