import React, { useMemo, useState, useEffect, useRef } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData, chatfolder } from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showConfirmationToast } from "../../../services/toaster/ConfirmToaster";

const breadcrumbItems = [];

const TagMasterList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let isToastOpenRef = useRef(false);

  useEffect(() => {
    getData();
  }, []);

  // get listing data
  const getData = async () => {
    try {
      const payload = {
        modelName: "temp_tag_masters",
        relations: [
          {
            module: "temp_tag_color_masters",
            moduleas: "temp_tag_color_masters",
            // required: true,
          },
        ],
        whereCondition: {
          is_default: 1,
          is_deleted: 0,
        },
      };

      const responseData = await fetchData(
        "getMasterList",
        payload,
        chatfolder
      );
      if (responseData && responseData.code) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);

        setData(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // add button navigate function
  const handleClickAdd = () => {
    navigate("/tag-create");
  };

  const handleEditClick = (id) => {
    navigate(`/tag-create/${id}`);
  };

  const handleDeleteClick = async (id) => {
    if (isToastOpenRef.current) return;
    isToastOpenRef.current = true;
    
    try {
      const onConfirm = async () => {
        const payload = {
          id: id,
          modelName: "temp_tag_masters",
          inputData: {
            is_deleted: 1,
          },
        };

        const responseData = await fetchData(
          "createAndUpdateMaster",
          payload,
          chatfolder
        );
        if (responseData && responseData.code === 1) {
          toast.success("Tag Deleted!");
          getData();
        } else {
          toast.error("Error while deleting the Tag.");
        }
        isToastOpenRef.current = false;
      };

      const onCancel = () => {
        toast.info("Deletion cancelled");
        isToastOpenRef.current = false;
      };
      const onClose = () => {
        isToastOpenRef.current = false;
      };

      showConfirmationToast(
        "Are you sure you want to delete this Tag?",
        onConfirm,
        onCancel,
        onClose
      );
    } catch (error) {
      console.error(error);
    }
  };
  console.log(isToastOpenRef.current);

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No.",
        accessor: "index",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Tag Name",
        accessor: "tag_name",
        accessor: (row) => (row.tag_name && row.tag_name ? row.tag_name : "-"),
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Tag Color Name",
        accessor: (row) =>
          row.temp_tag_color_masters &&
          row.temp_tag_color_masters.tag_color_name
            ? row.temp_tag_color_masters.tag_color_name
            : "-",
        disableFilters: true,
        filterable: false,
      },

      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (value === 0 ? "In Active" : "Active"),
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <div>
            <Button
              color="primary"
              onClick={() => handleEditClick(row.original.id)}>
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              color="danger"
              onClick={() => handleDeleteClick(row.original.id)}
              style={{ marginLeft: "10px" }}>
              <i className="fas fa-trash"></i>
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs title="Tag List" breadcrumbItems={breadcrumbItems} />
            <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}>
              Create Tag
            </Button>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TagMasterList;
